import React, { useEffect, useState } from 'react';
import JobForm from './JobForm';
import '../App.css';
import { HOURLY_PAY_RATE } from '../constants/constants.js';
import { FaCog, FaCheck } from 'react-icons/fa';

function JobList() {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [familyId, setFamilyId] = useState('');
  const [isEditingFamilyId, setIsEditingFamilyId] = useState(false);
  const [tempFamilyId, setTempFamilyId] = useState('');
  const [userType, setUserType] = useState(getUserType());

  useEffect(() => {
    // Parse the query string to get the family_id
    const params = new URLSearchParams(window.location.search);
    const queryFamilyId = params.get('family_id');
    const storedFamilyId = localStorage.getItem('family_id');

    if (queryFamilyId) {
      setFamilyId(queryFamilyId);
      localStorage.setItem('family_id', queryFamilyId); // Save to localStorage
    } else if (storedFamilyId) {
      setFamilyId(storedFamilyId);
    }

    fetchJobs(queryFamilyId || storedFamilyId);
  }, []);

  const fetchJobs = (familyId) => {
    fetch('/api/jobs', {
      headers: {
        'Content-Type': 'application/json',
        'Family-ID': familyId, // Include the family_id in the headers
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setJobs(data['hydra:member']);
        setError(null); // Clear any previous errors
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
        setError('Failed to fetch jobs. Backend is not available.');
      });
  };

  const markAsPaid = (id) => {
    fetch(`/api/jobs/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/merge-patch+json',
        'Family-ID': familyId, // Include the family_id in the headers
      },
      body: JSON.stringify({ paid: true }),
    }).then((response) => {
      if (response.ok) {
        fetchJobs(familyId); // Refresh the job list after updating
      }
    });
  };

  const handleEditFamilyId = () => {
    setTempFamilyId(familyId || '');
    setIsEditingFamilyId(true);
  };

  const handleSaveFamilyId = () => {
    setFamilyId(tempFamilyId);
    localStorage.setItem('family_id', tempFamilyId); // Save to localStorage
    setIsEditingFamilyId(false);
    fetchJobs(tempFamilyId); // Fetch jobs with the new family ID
  };

  function toggleUserType() {
    const newUserType = userType === 'Kid' ? 'Parent' : 'Kid';
    setUserType(newUserType);
  }
  function getUserType() {
    const savedUserType = localStorage.getItem('userType');
    return savedUserType ? savedUserType : 'Kid'; // Default to 'Kid' if not set
  }

  // Whenever userType changes, save it to local storage
  useEffect(() => {
    localStorage.setItem('userType', userType);
  }, [userType]);

  return (
    <div className="container hundredPercent">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <label>Family ID: </label>
          {isEditingFamilyId ? (
            <input
              type="text"
              value={tempFamilyId}
              onChange={(e) => setTempFamilyId(e.target.value)}
              onBlur={handleSaveFamilyId}
              autoFocus
            />
          ) : (
            <span onClick={handleEditFamilyId} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              {familyId || 'Click to set Family ID'}
            </span>
          )}
        </div>
        <FaCog onClick={toggleUserType} style={{ cursor: 'pointer' }} />
      </div>
      <p>User Type: {userType}</p>
      {userType === 'Kid' && <JobForm onJobCreated={() => fetchJobs(familyId)} />}
      {error && <p className="error">{error}</p>}
      {jobs.length > 0 ? (
        <table id="jobs">
          <thead>
            <tr>
              <th>Description</th>
              <th>Time</th>
              <th>££</th>
              {userType === 'Parent' && (
              <th>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {jobs.map((job) => (
              <tr key={job.id}>
                <td className="left description-column">{job.description}</td>
                <td className="right time">{job.hours ?? 0}:{String(job.minutes).padStart(2, '0')}</td>
                <td className={'right ' + (job.paid ? 'paid' : 'unpaid')}>£{(((job.hours ?? 0) + job.minutes / 60) * HOURLY_PAY_RATE).toFixed(2)}</td>
                {userType === 'Parent' && (
                  <td>
                    {!job.paid && (
                      <FaCheck onClick={() => markAsPaid(job.id)} style={{ cursor: 'pointer' }} />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No jobs available</p>
      )}
    </div>
  );
}

export default JobList;